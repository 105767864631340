<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("email change") }}</v-toolbar-title>
      <v-spacer />
      <wiki slug="settings-email" />
    </v-toolbar>
    <v-progress-linear :active="loadingDetails || loading" indeterminate />
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-card-text>
        <email-list-input
          v-model="emailValue"
          :name="'address.email'"
          :label="$t('email')"
          :hint="$t('main and billing email address')"
          :max-length="maxLength != null ? maxLength : null"
          :multiple="multiple"
          :required="required"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          name="changeEmail"
          color="primary"
          :loading="loadingDetails || loading"
          :disabled="
            loadingDetails || loading || invalid || email == emailValue
          "
          @click="changeEmail()"
          text
        >
          {{ $t("email change") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import EmailListInput from "@/components/basics/EmailListInput";
import PhoneListInput from "@/components/basics/PhoneListInput";
import showErrors from "@/utils/mixins/showErrors";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "EmailChange",
  components: {
    EmailListInput,
    PhoneListInput,
    Wiki,
  },
  mixins: [showErrors],
  props: {
    email: {
      type: String,
      required: true,
    },
    loadingDetails: {
      type: Boolean,
      required: true,
    },
    maxLength: {
      validator: (prop) =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    loading: false,
    emailValue: "",
    phoneValue: "",
  }),
  watch: {
    email: {
      immediate: true,
      handler(value) {
        this.emailValue = value;
      },
    },
  },
  methods: {
    changeEmail() {
      var that = this;
      this.loading = true;
      this.$http
        .patch("customers/" + this.$store.state.session.id, {
          address: { email: this.emailValue },
        })
        .then(function (response) {
          that.$emit("emailChanged", that.emailValue);
          that.$snotify.success(that.$i18n.t("emailHasBeenChanged"));
        })
        .catch(function (error) {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "email change": "Email change",
    "email": "Email",
    "phone": "Phone",
    "main and billing email address": "main and billing email address",
    "emailHasBeenChanged": "To confirm the change of your billing email, you will receive a message to your old email address with a confirmation link."
  },
  "de": {
    "email change": "Email ändern",
    "email": "Email",
    "phone": "Telefon",
    "main and billing email address": "Haupt- und Rechnungs Email Adresse",
    "emailHasBeenChanged": "Um die Änderung Ihrer Rechnungs Email Adresse zu bestätigen, erhalten Sie eine Nachricht an Ihre alte Email Adresse mit einem Bestätigungs Link."
  }
}
</i18n>
