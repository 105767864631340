<template>
  <div>
    <email-change
      class="mb-4"
      :email="address.email || ''"
      :loadingDetails="loadingDetails"
      max-lenght="100"
      :multiple="!$store.getters.isPerson"
      @emailChanged="readDetails"
    />
    <customer-worker-settings
      class="mb-4"
      @customerUpdated="readDetails"
      :billingType="billingType"
      :billingPeriod="billingPeriod"
      :openProfileChangeWorkers="openProfileChangeWorkers"
      :loadingDetails="loadingDetails"
    />
    <customer-immediately-settings
      class="mb-4"
      @customerUpdated="readDetails"
      :birthdate="isNaturalPerson ? birthdate : null"
      :partner-visible="partnerVisible"
      :show-partner-visible="showPartnerVisible"
      :newsletter="newsletter"
      :language="language"
      :logo="logo"
      :loadingDetails="loadingDetails"
    />
    <address-settings
      class="mb-4"
      @customerUpdated="readDetails"
      :address="address"
      :openAddressChangeWorkers="openAddressChangeWorkers"
      :loadingDetails="loadingDetails"
    />
  </div>
</template>

<script>
import EmailChange from "@/components/settings/EmailChange";
import CustomerImmediatelySettings from "@/components/settings/CustomerImmediatelySettings";
import CustomerWorkerSettings from "@/components/settings/CustomerWorkerSettings";
import AddressSettings from "@/components/settings/AddressSettings";

export default {
  name: "CustomerSettings",
  components: {
    EmailChange,
    CustomerImmediatelySettings,
    CustomerWorkerSettings,
    AddressSettings,
  },
  data: () => ({
    details: {},
    address: {},
    workers: {},
    openProfileChangeWorkers: 0,
    openAddressChangeWorkers: 0,
    loadingDetails: false,
  }),
  computed: {
    isNaturalPerson() {
      return (
        this.address.company_name == null || this.address.company_name == ""
      );
    },
    birthdate() {
      return this.address.birthdate != null ? this.address.birthdate : null;
    },
    partnerVisible() {
      return this.details.partner_visible != null
        ? this.details.partner_visible
        : null;
    },
    showPartnerVisible() {
      return this.details.partner_id != null;
    },
    newsletter() {
      return this.details.newsletter != null ? this.details.newsletter : null;
    },
    language() {
      return this.details.language != null
        ? this.details.language.toLowerCase()
        : null;
    },
    logo() {
      return this.details.logo_uploaded != null
        ? this.details.logo_uploaded
        : false;
    },
    billingType() {
      return this.details.billing_type != null
        ? this.details.billing_type
        : null;
    },
    billingPeriod() {
      return this.details.billing_period != null
        ? this.details.billing_period
        : null;
    },
  },
  methods: {
    readDetails: function () {
      if (this.$store.getters.isReady) {
        var that = this;
        this.loadingDetails = true;
        this.$http
          .get("customers/" + this.$store.state.session.id)
          .then(function (response) {
            that.details = response.data;
            that.address = response.data.address;
            that.openProfileChangeWorkers = response.data.workers.length;
            that.openAddressChangeWorkers =
              response.data.address.workers.length;
            // reload profile
            that.$http
              .get("profile", { disableDefaultErrorHandler: true })
              .then((response) => {
                that.$store.commit("setSession", response.data);
              });
          })
          .catch(function (error) {})
          .finally(function () {
            that.loadingDetails = false;
          });
      }
    },
  },
  created: function () {
    this.readDetails();
  },
};
</script>
