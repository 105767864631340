<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("customer settings") }}</v-toolbar-title>
      <v-spacer />
      <wiki slug="settings-customer" />
    </v-toolbar>
    <v-progress-linear :active="loadingDetails || loading" indeterminate />
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-form @submit="changeCustomer" onSubmit="return false;">
        <v-card-text>
          <validation-provider
            vid="billing_type"
            :name="$t('billing type')"
            v-slot="{ errors, valid, classes }"
          >
            <v-select
              name="billing_type"
              v-model="billingTypeValue"
              :items="billingTypes"
              item-text="title_de"
              item-value="id"
              item-disabled="disabled"
              :label="$t('billing type')"
              :error-messages="errors"
              :disabled="openProfileChangeWorkers > 0"
              :class="classes"
              persistent-hint
              menu-props="auto"
            >
              <template v-slot:selection="{ item }">
                {{ getBillingTypeTitle(item) }}
              </template>
              <template v-slot:item="{ item }">
                {{ getBillingTypeTitle(item) }} {{ getBillingTypeNote(item) }}
              </template>
            </v-select>
          </validation-provider>
          <validation-provider
            vid="billing_period"
            :name="$t('billing period')"
            v-slot="{ errors, valid, classes }"
          >
            <v-select
              name="billing_period"
              v-model="billingPeriodValue"
              :items="billingPeriodes"
              item-text="title"
              item-value="id"
              :label="$t('billing period')"
              :error-messages="errors"
              :disabled="
                openProfileChangeWorkers > 0 ||
                $store.state.session.partnerType === 'WHO'
              "
              :class="classes"
              persistent-hint
              menu-props="auto"
            />
          </validation-provider>
          <v-alert type="info" class="mt-4" v-if="openProfileChangeWorkers > 0">
            {{
              $tc("hintMsg", openProfileChangeWorkers, {
                n: openProfileChangeWorkers,
              })
            }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            name="changeCustomer"
            color="primary"
            :disabled="
              loadingDetails ||
              loading ||
              invalid ||
              unchanged ||
              openProfileChangeWorkers > 0
            "
            @click="changeCustomer()"
            text
          >
            {{ $t("request change") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "CustomerWorkerSettings",
  mixins: [showErrors],
  props: [
    "billingType",
    "billingPeriod",
    "openProfileChangeWorkers",
    "loadingDetails",
  ],
  components: {
    Wiki,
  },
  data: function () {
    return {
      loading: false,
      billingPeriodValue: this.billingPeriod,
      billingTypeValue: this.billingType,
      billingPeriodes: [
        { id: "MIV", title: this.$i18n.t("monthly in advance") },
        { id: "QIV", title: this.$i18n.t("quarterly in advance") },
        { id: "HIV", title: this.$i18n.t("every six months in advance") },
        { id: "JIV", title: this.$i18n.t("annually in advance") },
      ],
    };
  },
  computed: {
    isWho() {
      return this.$store.state.partnerType === "WHO";
    },
    unchanged() {
      return (
        this.billingPeriodValue === this.billingPeriod &&
        this.billingTypeValue === this.billingType
      );
    },
    billingTypes() {
      return this.$config.billingTypes.filter(function (item) {
        return item.visible;
      });
    },
  },
  watch: {
    billingType: function () {
      this.billingTypeValue = this.billingType;
    },
    billingPeriod: function () {
      this.billingPeriodValue = this.billingPeriod;
    },
  },
  methods: {
    changeCustomer: function () {
      var that = this;
      this.loading = true;
      this.$http
        .patch("customers/" + this.$store.state.session.id, {
          billing_period: this.billingPeriodValue,
          billing_type: this.billingTypeValue,
        })
        .then(function (response) {
          that.$snotify.success(that.$i18n.t("notifyMsg"));
          that.$emit("customerUpdated");
        })
        .catch(function (error) {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getBillingTypeTitle(item) {
      let locale = this.$store.state.locale.toLowerCase();
      let localeDefault = this.$config.i18nFallbackLocale.toLowerCase();
      if (item.hasOwnProperty("title_" + locale))
        return item["title_" + locale];
      else return item["title_" + localeDefault];
    },
    getBillingTypeNote(item) {
      let locale = this.$store.state.locale.toLowerCase();
      let localeDefault = this.$config.i18nFallbackLocale.toLowerCase();
      let note = "";
      if (item.hasOwnProperty("note_" + locale)) note = item["note_" + locale];
      else if (item.hasOwnProperty("note_" + localeDefault))
        note = item["note_" + localeDefault];
      else if (item.disabled) note = this.$i18n.t("contact support");
      if (note) return " (" + note + ")";
      return "";
    },
  },
};
</script>

<i18n>
{
  "en": {
    "customer settings": "Customer settings",
    "billing type": "Billing type",
    "request change": "Request change",
    "billing period": "Billing period",
    "monthly in advance": "Monthly in advance",
    "quarterly in advance": "Quarterly in advance",
    "every six months in advance": "Every six months in advance",
    "annually in advance": "Annually in advance",
    "hintMsg": " | There are {n} open change in processing, please check your tasks. | There are {n} open changes in processing, please check your tasks.",
    "notifyMsg": "Your changes has been successfully commited.",
    "contact support": "contact support please"
  },
  "de": {
    "customer settings": "Kundeneinstellungen",
    "billing type": "Rechnungsart",
    "request change": "Änderung beantragen",
    "billing period": "Verrechungsperiode",
    "monthly in advance": "Monatlich im voraus",
    "quarterly in advance": "Quartalsweise im voraus",
    "every six months in advance": "Halbjährlich im voraus",
    "annually in advance": "Jährlich im voraus",
    "hintMsg": " | Eine Änderung wurde beantragt (siehe Menu Aufträge). | Es gibt bereits {n} laufende Änderungen, bitte prüfen Sie Ihre Aufträge.",
    "notifyMsg": "Ihre Änderungen wurden erfolgreich übermittelt.",
    "contact support": "bitte Support kontaktieren"
  }
}
</i18n>
