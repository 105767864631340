<template>
  <v-row align="center" justify="center">
    <v-col cols="12" lg="8" md="12">
      <sak-settings class="mb-4" v-if="$config.mandant == 'sak'" />
      <password-change class="mb-4" v-else />
      <customer-settings />
    </v-col>
  </v-row>
</template>

<script>
import PasswordChange from "@/components/settings/PasswordChange";
import CustomerSettings from "@/components/settings/CustomerSettings";
import SakSettings from "@/components/settings/SakSettings";

export default {
  name: "MainSettings",
  components: {
    PasswordChange,
    CustomerSettings,
    SakSettings,
  },
};
</script>

<i18n>
{
  "en": {
    "customer settings": "Customer settings",
    "language": "Language"
  },
  "de": {
    "customer settings": "Abmelden",
    "language": "Sprache"
  }
}
</i18n>
