<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("address settings") }}</v-toolbar-title>
        <v-spacer />
        <wiki slug="settings-address" />
      </v-toolbar>
      <v-progress-linear :active="loadingDetails || loading" indeterminate />
      <validation-observer ref="obs" v-slot="{ errors, invalid }">
        <v-card-text>
          <v-card outlined class="mb-4">
            <v-card-text>
              <address-names
                v-model="addressValue"
                is-corporate-hide
                company-force-show
                company-max-length="100"
                first-name-max-length="30"
                last-name-max-length="30"
                :name-prefix="'address.'"
              />
            </v-card-text>
          </v-card>

          <v-card outlined class="mb-4">
            <v-card-text>
              <address-postal
                v-model="addressValue"
                additional-max-length="255"
                zip-name="zip_code"
                zip-max-length="10"
                city-max-length="100"
                street-max-length="100"
                street-mumber-max-length="10"
                :name-prefix="'address.'"
              />
            </v-card-text>
          </v-card>

          <v-card outlined>
            <v-card-text>
              <address-contacts
                v-model="addressValue"
                phone-max-length="16"
                mobile-max-length="16"
                fax-max-length="16"
                email-hide
                :name-prefix="'address.'"
              />
            </v-card-text>
          </v-card>

          <v-alert type="info" class="mt-4" v-if="openAddressChangeWorkers > 0">
            {{
              $tc("hintMsg", openAddressChangeWorkers, {
                n: openAddressChangeWorkers,
              })
            }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mb-4 mr-4"
            color="primary"
            :disabled="
              loadingDetails ||
              loading ||
              invalid ||
              !isChanged ||
              openAddressChangeWorkers > 0
            "
            @click="changeAddress"
            text
          >
            {{ $t("request change") }}
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>

    <v-card class="mb-4">
      <validation-observer ref="obs2" v-slot="{ errors, invalid }">
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("additional addresses") }}</v-toolbar-title>
          <v-spacer />
          <v-tooltip top v-if="!addresses.length > 0">
            <template v-slot:activator="{ on }">
              <v-btn @click="addAddress()" v-on="on" icon>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("add additional address") }}</span>
          </v-tooltip>
          <wiki slug="settings-address-additional" />
        </v-toolbar>
        <v-progress-linear :active="loadingDetails || loading" indeterminate />
        <v-card-text>
          <v-expansion-panels
            v-model="activeExpansionPanel"
            v-if="addresses.length > 0"
            multiple
          >
            <v-expansion-panel v-for="(address, i) in addresses" :key="i">
              <v-expansion-panel-header>
                {{ addresses[i].title }}
                <!-- <template v-slot:actions>
                  <v-icon v-if="addresses[i].default === true" color="orange">
                    mdi-star
                  </v-icon>
                  <v-icon v-else>
                    mdi-chevron-down
                  </v-icon>
                </template> -->
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      large
                      @click="setDefault(addresses[i])"
                      icon
                      v-if="addresses[i].default === false"
                    >
                      <v-icon>
                        mdi-star-outline
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-on="on"
                      large
                      @click="addresses[i].default = false"
                      icon
                      v-else
                    >
                      <v-icon color="orange">
                        mdi-star
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("make default") }}</span>
                </v-tooltip> -->

                <validation-provider
                  :vid="'delivery_addresses.' + i + '.title'"
                  :name="$t('title')"
                  rules="max:100"
                  v-slot="{ valid, dirty, errors, classes }"
                >
                  <v-text-field
                    v-model="addresses[i].title"
                    :name="'delivery_addresses.' + i + '.title'"
                    :label="$t('title')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                  />
                </validation-provider>
                <v-card outlined class="my-4">
                  <v-card-text>
                    <address-names
                      v-model="addresses[i]"
                      company-name="company_name"
                      :company-rules="addresses[i].last_name ? '' : 'required'"
                      company-max-length="100"
                      first-name-max-length="100"
                      last-name-max-length="100"
                      :last-name-rules="
                        addresses[i].company_name ? '' : 'required'
                      "
                      :name-prefix="'delivery_addresses.' + i + '.'"
                    />
                  </v-card-text>
                </v-card>
                <v-card outlined class="mb-4">
                  <v-card-text>
                    <address-postal
                      v-model="addresses[i]"
                      additional-max-length="255"
                      zip-name="zip_code"
                      zip-max-length="10"
                      city-max-length="100"
                      street-max-length="100"
                      street-mumber-max-length="10"
                      :name-prefix="'delivery_addresses.' + i + '.'"
                    />
                  </v-card-text>
                </v-card>
                <v-card outlined class="mb-4">
                  <v-card-text>
                    <address-contacts
                      v-model="addresses[i]"
                      phone-max-length="16"
                      mobile-max-length="16"
                      fax-max-length="16"
                      email-max-length="100"
                      email-rules="required"
                      email-multiple
                      :name-prefix="'delivery_addresses.' + i + '.'"
                    />
                  </v-card-text>
                </v-card>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="error"
                    @click="
                      addresses.splice(i, 1);
                      activeExpansionPanel = null;
                    "
                    text
                  >
                    {{ $t("delete") }}
                  </v-btn>
                </v-card-actions>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <template v-else>
            {{ $t("no additional addresses") }}
          </template>
        </v-card-text>
        <v-card-actions
          v-if="
            ($store.state.session.deliveryAddresses != null &&
              $store.state.session.deliveryAddresses.length > 0) ||
            addresses.length > 0
          "
        >
          <v-spacer />
          <v-btn
            @click="resetAddresses()"
            :disabled="loadingDetails || loading"
            :loading="loadingDetails || loading"
            text
          >
            {{ $t("reset") }}
          </v-btn>
          <v-btn
            @click="changeDeliveryAddresses()"
            :disabled="loadingDetails || loading"
            :loading="loadingDetails || loading"
            primary
            text
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
/*
TOPO:
__________________________________________________________________________
<v-tooltip top v-if="!addresses.length > 0"> --> <v-tooltip top>
__________________________________________________________________________
this.addresses.push({
  default: true,
  title: this.$i18n.t("new address"),
  country: "CH",
  salutation: "n",
});
-->
this.addresses.push({
  default: false,
  title: this.$i18n.t("new address"),
  country: "CH",
  salutation: "n",
});
__________________________________________________________________________
wieder einblenden
<!-- <template v-slot:actions>
  <v-icon v-if="addresses[i].default === true" color="orange">
    mdi-star
  </v-icon>
  <v-icon v-else>
    mdi-chevron-down
  </v-icon>
</template> -->
__________________________________________________________________________
wieder einblenden
<!-- <v-tooltip top>
  <template v-slot:activator="{ on }">
    <v-btn
      v-on="on"
      large
      @click="setDefault(addresses[i])"
      icon
      v-if="addresses[i].default === false"
    >
      <v-icon>
        mdi-star-outline
      </v-icon>
    </v-btn>
    <v-btn
      v-on="on"
      large
      @click="addresses[i].default = false"
      icon
      v-else
    >
      <v-icon color="orange">
        mdi-star
      </v-icon>
    </v-btn>
  </template>
  <span>{{ $t("make default") }}</span>
</v-tooltip> -->
__________________________________________________________________________

*/
import AddressNames from "@/components/basics/AddressNames";
import AddressPostal from "@/components/basics/AddressPostal";
import AddressContacts from "@/components/basics/AddressContacts";
import showErrors from "@/utils/mixins/showErrors";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "AddressSettings",
  mixins: [showErrors],
  components: {
    AddressNames,
    AddressPostal,
    AddressContacts,
    Wiki,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    openAddressChangeWorkers: {
      type: Number,
      default: 0,
    },
    loadingDetails: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    addressValue: {},
    addresses: [],
    activeExpansionPanel: [],
  }),
  watch: {
    address: {
      immediate: true,
      deep: true,
      handler() {
        if (this.address) {
          this.addressValue = { ...this.address };
        }
      },
    },
    "$store.state.session.addresses": function () {
      this.addresses = this.$store.getters.deliveryAddresses;
    },
  },
  computed: {
    isChanged() {
      this.address; // force re-compute
      for (const [k, v] of Object.entries(this.addressValue)) {
        if (
          k in this.address &&
          this.convert(this.address[k]) !== this.convert(v)
        )
          return true;
      }
      return false;
    },
  },
  methods: {
    convert(v) {
      if (v === undefined || v === null) return "";
      return v;
    },
    changeAddress() {
      var that = this;
      this.loading = true;
      this.$http
        .patch("customers/" + this.$store.state.session.id, {
          address: this.addressValue,
        })
        .then(function (response) {
          // reset address

          that.addressValue = { ...that.address };
          that.$snotify.success(that.$i18n.t("notifyMsg"));
          that.$emit("customerUpdated");
        })
        .catch(function (error) {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    changeDeliveryAddresses() {
      var that = this;
      this.loading = true;

      this.$http
        .patch("customers/" + this.$store.state.session.id, {
          delivery_addresses: this.addresses,
        })
        .then((response) => {
          that.$snotify.success(that.$i18n.t("delivery address changed"));
          that.$store.commit(
            "setdeliveryAddresses",
            response.data.delivery_addresses
          );
          that.activeExpansionPanel = [];
        })
        .catch((error) => {
          that.$refs.obs2.setErrors(error.data);
          error.response.data.delivery_addresses.forEach(function (
            item,
            index
          ) {
            if (Object.keys(item).length > 0) {
              that.activeExpansionPanel.push(index);
            }
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    setDefault(addressObject) {
      this.addresses.forEach((element) => {
        element.default = false;
      });
      addressObject.default = true;
    },
    addAddress() {
      this.addresses.push({
        default: true,
        title: this.$i18n.t("new address"),
        country: "CH",
        salutation: "n",
      });
      // this.activeExpansionPanel = this.addresses.length - 1;
    },
    resetAddresses() {
      // console.log("reset old", this.addresses);
      // console.log("reset store", this.$store.state.session.deliveryAddresses);
      // console.log("reset new", this.$store.getters.deliveryAddresses);
      // Weil der scheiss motherfucker nach einem mal keine deepcopie erstellt im getter
      this.addresses = JSON.parse(
        JSON.stringify(this.$store.getters.deliveryAddresses)
      );
    },
  },
  mounted() {
    this.resetAddresses();
    this.$refs.obs.validate();
  },
};
</script>

<i18n>
{
  "en": {
    "address settings": "Address settings",
    "request change": "Request change",
    "notifyMsg": "Your changes has been successfully commited.",
    "hintMsg": " | There are {n} open address change in processing, please check your tasks! | There are {n} open address changes in processing, please check your tasks!",
    "additional addresses": "Different address / Mail address for orders",
    "add additional address":"Add additional address",
    "no additional addresses":"No additional addresses saved",
    "delete":"Delete",
    "save":"Save",
    "reset":"Reset",
    "make default": "Make this delivery address and email default on any checkout form",
    "new address": "New address",
    "delivery address changed":"Delivery addresses saved",
    "title":"Title"
  },
  "de": {
    "address settings": "Adresseinstellungen",
    "request change": "Änderung beantragen",
    "notifyMsg": "Ihre Änderungen wurden erfolgreich übermittelt.",
    "hintMsg": " | Es gibt eine laufende Adressänderung, bitte prüfen Sie Ihre Vorgänge! | Es gibt {n} laufende Adressänderungen, bitte prüfen Sie Ihre Vorgänge!",
    "additional addresses": "Abweichende Anschrift / Mailadresse für Bestellungen",
    "add additional address":"Zusätzliche Addresse hinzufügen",
    "no additional addresses":"Keine zusätzlichen Addressen vorhanden",
    "delete":"Löschen",
    "save":"Speichern",
    "reset":"Zurücksetzen",
    "make default": "Addresse soll bei Bestellformularen voreingestellt sein",
    "new address": "Neue Addresse",
    "delivery address changed":"Lieferaddressen gespeichert",
    "title":"Titel"
  }
}
</i18n>
