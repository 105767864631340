<template>
  <v-card>
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-card-text>
        <v-row v-if="birthdate != null">
          <v-col cols="6">
            <v-text-field
              :value="formatDate(birthdate)"
              name="birthdate"
              :label="$t('birthdate')"
              disabled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="showPartnerVisible">
            <validation-provider
              vid="partner_visible"
              :name="$t('partner visible')"
              v-slot="{ errors, valid, classes }"
            >
              <v-switch
                name="partner_visible"
                v-model="partnerVisibleValue"
                :label="$t('partner visible')"
                :hint="$t('partnerVisibleHint')"
                :error-messages="errors"
                :loading="loadingDetails || loadingPartnerVisible"
                :disabled="loadingDetails || loadingPartnerVisible"
                :class="classes"
                :prepend-icon="
                  partnerVisibleValue ? 'mdi-eye-lock-open' : 'mdi-eye-lock'
                "
                persistent-hint
              />
            </validation-provider>
          </v-col>
          <v-col :cols="showPartnerVisible ? 6 : 12">
            <validation-provider
              vid="newsletter"
              :name="$t('newsletter')"
              v-slot="{ errors, valid, classes }"
            >
              <v-switch
                name="newsletter"
                v-model="newsletterValue"
                :label="$t('subscribe newsletter')"
                :hint="$t('newsletterHint')"
                :error-messages="errors"
                :loading="loadingDetails || loadingNewsletter"
                :disabled="loadingDetails || loadingNewsletter"
                :class="classes"
                :prepend-icon="newsletterValue ? 'mdi-email' : 'mdi-email-off'"
                persistent-hint
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <language-select
              v-model="languageValue"
              :hint="$t('languageHint')"
              :loading="loadingDetails || loadingLanguage"
              :disabled="loadingDetails || loadingLanguage"
              persistent-hint
            />
          </v-col>
          <v-col cols="6">
            <validation-provider
              vid="logo"
              :name="$t('company logo')"
              v-slot="{ errors, classes }"
            >
              <v-file-input
                class="mt-4"
                name="logo"
                v-model="logoValue"
                :hint="$t('pick your company logo')"
                :label="$t('company logo')"
                :error-messages="errors"
                :loading="loadingDetails || loadingLogo"
                :disabled="loadingDetails || loadingLogo"
                :class="classes"
                prepend-icon="mdi-camera-retake"
                accept="image/png, image/jpeg"
                :clearable="!isMobile"
              >
                <template v-slot:prepend-inner v-if="logo">
                  <v-avatar>
                    <img v-auth-image="logoUrl" />
                  </v-avatar>
                </template>

                <template v-slot:selection="{ file }">
                  <v-chip small label color="primary">
                    {{ file.name }} - {{ prettyBytes(file.size) }}
                  </v-chip>
                </template>
              </v-file-input>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
    </validation-observer>
  </v-card>
</template>

<script>
import prettyBytes from "@/utils/mixins/prettyBytes";
import forceRerenderApp from "@/utils/mixins/forceRerenderApp";
import LanguageSelect from "@/components/basics/LanguageSelect";
import showErrors from "@/utils/mixins/showErrors";
import formatDate from "@/utils/mixins/formatDate";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "CustomerImmediatelySettings",
  props: {
    birthdate: {
      type: String,
      required: false,
      default: null,
    },
    showPartnerVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    partnerVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    newsletter: {
      type: Boolean,
      required: false,
      default: false,
    },
    language: {
      type: String,
      required: false,
      default: null,
    },
    language: {
      type: String,
      required: false,
      default: null,
    },
    logo: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadingDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    LanguageSelect,
  },
  mixins: [prettyBytes, forceRerenderApp, showErrors, formatDate, isMobile],
  data: () => ({
    loadingPartnerVisible: false,
    loadingNewsletter: false,
    loadingLanguage: false,
    loadingLogo: false,
    partnerVisibleValue: null,
    languageValue: null,
    newsletterValue: null,
    logoValue: null,
    logoCounter: 0,
  }),
  computed: {
    logoUrl() {
      if (this.$store.state.session.id)
        return (
          this.$config.backendUrl +
          "customers/" +
          this.$store.state.session.id +
          "/logo?avatar=true&counter=" +
          this.logoCounter
        );
    },
  },
  watch: {
    partnerVisible: {
      immediate: true,
      handler(value) {
        this.partnerVisibleValue = value;
      },
    },
    newsletter: {
      immediate: true,
      handler(value) {
        this.newsletterValue = value;
      },
    },
    language: {
      immediate: true,
      handler(value) {
        this.languageValue = value;
      },
    },
    languageValue(newValue, oldValue) {
      if (oldValue != null) {
        var that = this;
        this.loadingLanguage = true;
        this.$http
          .patch("customers/" + this.$store.state.session.id, {
            language: newValue.toUpperCase(),
          })
          .then(function (response) {
            that.$emit("customerUpdated");
          })
          .catch(function (error) {
            if (error.invalid()) {
              that.$refs.obs.setErrors(error.data);
              that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
            } else
              that.$store.commit("setSystemError", {
                msg: error.message,
                title: error.title,
              });
          })
          .finally(function () {
            that.loadingLanguage = false;
          });
      }
    },
    newsletterValue(newValue, oldValue) {
      if (oldValue != null) {
        var that = this;
        this.loadingNewsletter = true;
        this.$http
          .patch("customers/" + this.$store.state.session.id, {
            newsletter: newValue,
          })
          .then(function (response) {
            that.$emit("customerUpdated");
          })
          .catch(function (error) {
            if (error.invalid()) {
              that.$refs.obs.setErrors(error.data);
              that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
            } else
              that.$store.commit("setSystemError", {
                msg: error.message,
                title: error.title,
              });
          })
          .finally(function () {
            that.loadingNewsletter = false;
          });
      }
    },
    partnerVisibleValue(newValue, oldValue) {
      if (oldValue != null) {
        var that = this;
        this.loadingPartnerVisible = true;
        this.$http
          .patch("customers/" + this.$store.state.session.id, {
            partner_visible: newValue,
          })
          .then(function (response) {
            that.$emit("customerUpdated");
          })
          .catch(function (error) {
            if (error.invalid()) {
              that.$refs.obs.setErrors(error.data);
              that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
            } else
              that.$store.commit("setSystemError", {
                msg: error.message,
                title: error.title,
              });
          })
          .finally(function () {
            that.loadingPartnerVisible = false;
          });
      }
    },
    logoValue: function () {
      if (this.logoValue) {
        var that = this;
        var formData = new FormData();
        formData.append("file", this.logoValue, this.logoValue.name);
        this.loadingLogo = true;
        this.$http
          .put(
            "customers/" + this.$store.state.session.id + "/logo",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
              },
            }
          )
          .then(function (response) {
            that.$emit("customerUpdated");
            that.logoCounter += 1;
            // re-render App menu only
            that.forceRerenderApp(true, false);
          })
          .catch(function (error) {
            if (error.invalid()) {
              that.$refs.obs.setErrors(error.data);
              that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
            } else
              that.$store.commit("setSystemError", {
                msg: error.message,
                title: error.title,
              });
          })
          .finally(function () {
            that.loadingLogo = false;
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "partner visible": "Visibility for partner",
    "partnerVisibleHint": "Your sales partner has access to sensitive technical product specifications and passwords.",
    "newsletter": "Newsletter",
    "newsletterHint": "You will be unsubscribed from all our newsletters.",
    "company logo": "Company logo",
    "subscribe newsletter": "Subscribe newsletter",
    "languageHint": "Change internal communication language",
    "pick your company logo": "Pick your company logo",
    "birthdate": "Birthdate"
  },
  "de": {
    "partner visible": "Sichtbarkeit für Partner",
    "partnerVisibleHint": "Ihr Vertriebspartner hat Einsicht auf sensible technische Produkt Spezifikationen und Passwörter.",
    "newsletter": "Newsletter",
    "newsletterHint": "Sie werden von allen unseren Newslettern abgemeldet.",
    "company logo": "Firmen Logo",
    "subscribe newsletter": "Newsletter abonnieren",
    "languageHint": "Ändert die interne Kommunikationssprache",
    "pick your company logo": "wählen Sie Ihr Firmen Logo aus",
    "birthdate": "Geburtsdatum"
  }
}
</i18n>
